import React, {Fragment} from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	Popconfirm,
	Row,
	Select,
	Space,
	Table,
	notification
} from "antd";
import { getStationDeviceStatusOptions , getStationDevicesState , STATION_DEVICES_STATUS } from "constants/device";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import StationDevicesService from "../../services/StationDevicesService";
import listDocumentaryService from "../../services/listDocumentaryService";
import ModalAddDevice from "./ModalAddDevice";
import ModalEditDevice from "./ModalEditDevice";
import { useSelector } from 'react-redux';
import { getStationDevicesError } from 'constants/errorMessage';
import UnLock from 'components/UnLock/UnLock';
import { isMobileDevice } from "constants/account";
import BasicTablePaging from 'components/BasicTablePaging/BasicTablePaging';

const { RangePicker } = DatePicker;

const { Option } = Select;
export default function Device() {
	const { t: translation } = useTranslation();
	const [listDocumentary, setListDocumentary] = useState({
		data: [],
		total: 0,
	});
	const setting = useSelector((state) => state.setting);
	const [isEditing, setIsEditing] = useState(false);
	const [isAdd, setIsAdd] = useState(false);
	const [formAdd] = Form.useForm()
	const [formEdit] = Form.useForm();
	const [crimePlateNumber, setCrimePlateNumber] = useState("");
	const [loading, setLoading] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState({});
	const [message, setMessage] = useState({
		message: "",
		button: translation("listCustomers.selectAll", {
			total: listDocumentary.total,
		}),
	});
	const [dataFilter, setDataFilter] = useState({
		filter: {
			documentPublishedDay: undefined
		},
		skip: 0, 
    limit: 20,
    searchText: undefined
	});
	const [dateBySelect, setDateBySelect] = useState("");
	const [fileSelected, setFileSelected] = useState(undefined);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedCustomers, setSelectedCustomers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [messageType, setMessageType] = useState("");
	const [isModalSMSOpen, setIsModalSMSOpen] = useState(false);
	const [customerId, setCustomerId] = useState();
	const [item, setItem] = useState([])
	const inputRef = useRef();

	const columns = [
		{
			title: translation("device.index"),
			key: "index",
			render: (_, __, index) => {
				return (
					<div className='d-flex justify-content-center aligns-items-center'>
						{dataFilter.skip ? dataFilter.skip + index + 1 : index + 1 }
					</div>
				)
			},
			width: 60,
			align: "center",
		},
		{
			title: translation("device.name"),
			key: "deviceName",
			dataIndex: "deviceName",
			width: 200,
			align: "center",
		},
		{
			title: translation("device.statusOption"),
			key: "deviceStatus",
			dataIndex: "deviceStatus",
			width: 180,
			align: "center",
			render: (deviceStatus, record) => {
				const stationDevicesState = getStationDevicesState(translation);
				const statusColors = {
					[STATION_DEVICES_STATUS.NEW]: 'green',
					[STATION_DEVICES_STATUS.ACTIVE]: 'blue',
					[STATION_DEVICES_STATUS.MAINTENANCE]: 'orange',
					[STATION_DEVICES_STATUS.INACTIVE]: 'red',
					[STATION_DEVICES_STATUS.MAINTENANCE_SERVICE]: 'purple',
					[STATION_DEVICES_STATUS.REPAIR]: 'yellow'
				};
				const color = statusColors[deviceStatus] || 'black';
		
				return <span style={{ color: color }}>{stationDevicesState[deviceStatus]}</span>;
			}
		},
		{
			title: translation("device.type"),
			key: "deviceType",
			dataIndex: "deviceType",
			width: 180,
			align: "center",
		},
		{
			title: translation("device.serialNumber"),
			key: "deviceSeri",
			dataIndex: "deviceSeri",
			width: 200,
			align: "center",
		},
		{
			title: translation("device.brand"),
			key: "deviceBrand",
			dataIndex: "deviceBrand",
			width: 160,
			align: "center",
		},
		{
			title: translation("device.manufactureYear"),
			key: "deviceManufactureYear",
			dataIndex: "deviceManufactureYear",
			width: 180,
			align: "center",
			render: (value) => (value === 0 ? "_" : value)
		},
		{
			title: translation("listCustomers.act"),
			key: "action",
			width: 280,
			align: "center",
			render: (_, record) => (
				<Space size="middle">
					<EditOutlined onClick={() =>{
						 toggleEditModal();
						 setItem(record);
					}} style={{ cursor: "pointer", color: "var(--primary-color)" }} />
					<Popconfirm
						title={translation("device.confirm-delete")}
						onConfirm={() => onDeleteDevice(record.stationDevicesId)}
						okText={translation("category.yes")}
						cancelText={translation("category.no")}
					>
						<DeleteOutlined style={{ cursor: "pointer", color: "var(--primary-color)" }} />
					</Popconfirm>
				</Space>
			),
		},
	];
	
	const onFilterByStatus = (selectedStatus) => {
		const newDataFilter = { ...dataFilter };
        newDataFilter.skip = 0;
		if (selectedStatus) {
			newDataFilter.filter.deviceStatus = selectedStatus;
		} else {
			delete newDataFilter.filter.deviceStatus;
		}
		setDataFilter(newDataFilter);
		fetchData(newDataFilter);
	};

	const onDeleteDevice = (id) => {
		StationDevicesService.delete({ id }).then((result) => {
			if (result && result.isSuccess) {
				notification["success"]({
					message: "",
					description: translation("device.deleteSuccess"),
				});
				fetchData(dataFilter);
			} else {
				notification["error"]({
					message: "",
					description: translation("device.deleteFailed"),
				});
			}
		});
	};

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			if (selectedRowKeys.length > 0) {
				if (selectedRowKeys.length === listDocumentary.total) {
					setMessage({
						button: translation("listCustomers.undo"),
						message: `${listDocumentary.total} ${translation(
							"listCustomers.selected"
						)}`,
					});
				} else if (selectedRowKeys.length === listDocumentary.data.length) {
					setMessage({
						button: translation("listCustomers.selectAll", {
							total: listDocumentary.total,
						}),
						message: `${selectedRowKeys.length} ${translation(
							"listCustomers.selected"
						)}`,
					});
				} else {
					setMessage({
						button: translation("listCustomers.selectAll", {
							total: listDocumentary.total,
						}),
						message: `${selectedRowKeys.length} ${translation(
							"listCustomers.selected"
						)}`,
					});
				}
				let arrCustomers = [];
				selectedRows.forEach((cus) => {
					arrCustomers.push(cus.customerRecordId);
				});
				setSelectedCustomers(arrCustomers);
				setSelectedRowKeys(selectedRowKeys);
			} else {
				setMessage({
					button: translation("listCustomers.selectAll", {
						total: listDocumentary.total,
					}),
					message: "",
				});
				setSelectedCustomers([]);
				setSelectedRowKeys([]);
			}
		},
	};

	const fetchData = (filter) => {
		StationDevicesService.getList(filter).then((result) => {
			if (result) {
				setListDocumentary(result);
			}
		});
	};

	useEffect(() => {
	isMobileDevice(window.outerWidth)
    if(isMobileDevice(window.outerWidth) === true){
      dataFilter.limit = 10
    }
		fetchData(dataFilter);
	}, []);

	const handleChangePage = (pageNum) => {
		const newFilter = {
		  ...dataFilter,
		  skip : (pageNum -1) * dataFilter.limit
		}
		setDataFilter(newFilter)
		fetchData(newFilter)
	  }

	const onSearch = (value) => {
		const newFilter = { ...dataFilter };
        newFilter.skip = 0;
		if (!value) {
			newFilter.searchText = undefined;
		} else {
			newFilter.searchText = value;
		}
		setDataFilter(newFilter)
		fetchData(newFilter);
	};

	const onFilterByDate = (date, dateString) => {
		const newDataFilter = { ...dataFilter };
		if (dateString) {
			newDataFilter.filter.documentPublishedDay = dateString
		} else {
			delete newDataFilter.filter.documentPublishedDay
		}
		setDataFilter(newDataFilter)
		fetchData(newDataFilter)
	};

	const toggleEditModal = () => {
		// Nếu tắt modal xem chi tiết gọi thêm fetchData.
		if (isEditing) {
			fetchData(dataFilter)
		}

		setIsEditing((prev) => !prev);
	};

	const toggleAddModal = () => {
		setIsAdd((prev) => !prev);
	};

	const onOpenModal = (customer) => {
		toggleEditModal();
		setSelectedCustomer(customer);
	};

	const onUpdateCustomer = (data, callback) => {
		StationDevicesService.update(data).then((result) => {
			callback();
			if (result && result.isSuccess) {
				notification["success"]({
					message: "",
					description: translation("device.updateSuccess"),
				});
				toggleEditModal();
				fetchData(dataFilter);
				return true;
			}

			if (Object.keys(getStationDevicesError(translation)).includes(result.error)) {
				notification['error']({
					message: '',
					description: getStationDevicesError(translation)[result.error]
				});
				return;
			}

			notification["error"]({
				message: "",
				description: translation("device.updateFailed"),
			});
			return false;
		});
	};

	const onCrateNew = (newData, callback) => {
		StationDevicesService.insert({
			...newData ,
			stationsId : setting.stationsId
		}).then(async result => {
			callback();
			if (result && result.isSuccess) {
				notification.success({
					message: "",
					description: translation('device.createSuccess')
				})
				isAdd && setIsAdd(false)
				formAdd.resetFields()
				fetchData(dataFilter)
			} else {
				if (Object.keys(getStationDevicesError(translation)).includes(result.error)) {
					notification['error']({
						message: '',
						description: getStationDevicesError(translation)[result.error]
					});
					return;
				}

				notification.error({
					message: "",
					description: translation('device.createFailed')
				})
			}
		})
	}

	const onChangeSearchText = (e) => {
		e.preventDefault()
		setDataFilter({ ...dataFilter,skip:0, searchText: e.target.value ? e.target.value : undefined })
	};

	return (
		<Fragment>
		{setting.enableDeviceMenu === 0 ? <UnLock /> :
		<main className="list_customers">
			<Row gutter={[16, 16]}>
				<Col xs={24} sm={24} md={24} lg={5} xl={5}>
					<label className="section-title pl-3">
						{translation("device.list")}
					</label>
				</Col>
				<Col xs={24} sm={24} md={24} lg={19} xl={19}>
					<Row gutter={[16, 16]} justify="end">
						<Col xs={24} sm={24} md={24} lg={12} xl={7}>
							<Input.Search
								placeholder={translation("device.searchByNameSerial")}
								onChange={onChangeSearchText}
								value={dataFilter.searchText}
								onSearch={onSearch}
							/>
						</Col>
						<Col xs={24} sm={12} md={12} lg={6} xl={4}>
							<Select
								className="w-100"
								placeholder={translation("device.statusOption")}
								onChange={onFilterByStatus}
								value={dataFilter.status}
								options={getStationDeviceStatusOptions(translation)}
							/>
						</Col>
						<Col xs={24} sm={12} md={12} lg={6} xl={5}>
							<Button type="primary" className="w-100" onClick={toggleAddModal}>
								{translation("device.btnNew")}
							</Button>
						</Col>
					</Row>

				</Col>
			</Row>

			<div className="list_customers__body">
				<Table
					dataSource={listDocumentary.data}
					columns={columns}
					scroll={{ x: 1440 }}
					pagination={false}
				/>
				<BasicTablePaging handlePaginations={handleChangePage} skip={dataFilter.skip} count={listDocumentary?.data?.length < dataFilter.limit}></BasicTablePaging>
			</div>
			{isEditing && (
				<ModalEditDevice
					isEditing={isEditing}
					form={formAdd}
					toggleEditModal={toggleEditModal}
					onUpdateCustomer={onUpdateCustomer}
					id={item.stationDevicesId}
				/>
			)}
			{isAdd && (
				<ModalAddDevice
					isAdd={isAdd}
					form={formAdd}
					inputRef={inputRef}
					onCrateNew={onCrateNew}
					toggleAddModal={toggleAddModal}
				/>
			)}
		</main>
        }
		</Fragment>
	);
}